import { useOrder, getQueryClient } from './OrderHooks'
import { formatMoney } from './Money'
import { QueryClientProvider } from 'react-query'
import { Disclosure } from '@headlessui/react'
import { SummaryList } from './BasketSummaryList'
import { useTranslation } from 'react-i18next'

const queryClient = getQueryClient()

const CheckoutSummaryInner = () => {
  const { t } = useTranslation()
  const { isLoading, isError, data: { data: basket = {} } = {} } = useOrder()

  return (
    <Disclosure>
      <div className="lg:hidden bg-gray-50 text-gray-800 p-3 px-4 sticky top-[64px] w-full">
        <div className="flex items-center">
          <div className="flex-1">
            <h2 className="font-medium">{t('frontend.checkout_summary.basket_summary')}</h2>
            <div className="text-gray-500 text-sm">
              {isLoading && <div className="bg-gray-200 animate-pulse h-5 w-[100px]" />}
              {!isLoading &&
                !isError &&
                t('frontend.checkout_summary.total_x', {
                  amount: formatMoney({
                    amount: basket.totals.payment_required,
                    currency: basket.currency,
                  }),
                })}
            </div>
          </div>
          <div className="flex-shrink-0">
            <Disclosure.Button className="font-medium text-accent">
              {t('frontend.checkout_summary.see_items')}
            </Disclosure.Button>
          </div>
        </div>
        <Disclosure.Panel className="mt-4">
          <SummaryList />
        </Disclosure.Panel>
      </div>
    </Disclosure>
  )
}

export const CheckoutSummary = () => (
  <QueryClientProvider client={queryClient}>
    <CheckoutSummaryInner />
  </QueryClientProvider>
)
