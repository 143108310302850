import React from 'react';
import { useState } from 'react';
import { AddressAutofill } from '@mapbox/search-js-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
export var addressDetailsClasses = 'py-3 focus:ring-violet focus:ring-2 focus:ring-offset-2 shadow-sm sm:text-sm border-gray-300 focus:border-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed text-gray-900';
export var AddressLookUp = function (_a) {
    var onChange = _a.onChange, _b = _a.showAddressProperties, showAddressProperties = _b === void 0 ? true : _b, _c = _a.defaultCountryCode, defaultCountryCode = _c === void 0 ? 'United Kingdom' : _c;
    var mapboxToken = process.env.MIX_MAPBOX_PUBLIC_KEY;
    var _d = useState(null), address = _d[0], setAddress = _d[1];
    var t = useTranslation().t;
    function handleAddressChange(mapboxAddressResponse) {
        onChange(mapboxAddressResponse.features[0].properties);
    }
    function renderAddressInputAsMapboxReactChild() {
        return (<input name="address" placeholder={t('frontend.add_voucher_button.search_address')} type="text" className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}/>);
    }
    return (<div>
      <AddressAutofill accessToken={mapboxToken} options={{
            language: 'en',
            country: defaultCountryCode,
        }} onRetrieve={function (value) { return handleAddressChange(value); }}>
        {renderAddressInputAsMapboxReactChild()}
      </AddressAutofill>
      {address !== null && showAddressProperties && (<div className="grid grid-cols-2 grid-rows-2 mt-4">
          <input placeholder={t('frontend.add_voucher_button.town')} type="text" autoComplete="address-level3" className={classNames(addressDetailsClasses)}/>
          <input placeholder={t('frontend.add_voucher_button.county')} type="text" autoComplete="address-level2" className={classNames(addressDetailsClasses)}/>
          <input placeholder={t('frontend.add_voucher_button.postcode')} type="text" autoComplete="postal-code" className={classNames(addressDetailsClasses)}/>
          <input placeholder={t('frontend.add_voucher_button.country')} type="text" autoComplete="country-name" className={classNames(addressDetailsClasses)}/>
        </div>)}
    </div>);
};
