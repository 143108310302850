import { createContext, useContext, useState } from 'react'

const OfferingContext = createContext()

const DEFAULT_GUEST = [
  {
    name: '',
  },
]

export const useOffering = () => useContext(OfferingContext)
export const OfferingProvider = (props) => {
  const [basketId, setBasketId] = useState(props.basketId ?? null)
  const [basketItemId, setBasketItemId] = useState(null)
  const [guests, setGuests] = useState(() => {
    if (props.basketGuests && props.basketGuests.length > 0) {
      return props.basketGuests
    }

    return DEFAULT_GUEST
  })

  window.setBasketGuests = setGuests

  const [numberOfGuests, setNumberOfGuests] = useState(props.isCouple ? 2 : guests.length)

  const setNameForGuest = (index, name) => {
    updateGuestDetails(index, { name })
  }

  const setFirstNameForGuest = (index, firstName) => {
    updateGuestDetails(index, { firstName })
  }

  const setLastNameForGuest = (index, lastName) => {
    updateGuestDetails(index, { lastName })
  }

  const updateGuestDetails = (index, details) => {
    const newGuests = [...guests]
    newGuests[index] = { ...newGuests[index], ...details }
    setGuests(newGuests)

    return newGuests
  }

  const addGuest = () => {
    const newGuest = { _id: Math.random().toString(16).slice(2), name: '' }
    setGuests([...guests].concat([newGuest]))

    return newGuest
  }

  const addGuests = (numberOfGuests, attributes = {}) => {
    const newGuests = []

    for (let i = 0; i < numberOfGuests; i++) {
      newGuests.push({ _id: Math.random().toString(16).slice(2), name: '', ...attributes })
    }

    const updated = [...guests].concat(newGuests)

    setGuests(updated)

    return updated
  }

  const removeGuest = (index = null) => {
    const newGuests = [...guests]
    const [removedGuest] = newGuests.splice(index, 1)

    setGuests(newGuests)

    return removedGuest
  }

  return (
    <OfferingContext.Provider
      value={{
        ...props,
        guests,
        setNameForGuest,
        setFirstNameForGuest,
        setLastNameForGuest,
        addGuest,
        addGuests,
        removeGuest,
        updateGuestDetails,
        setGuests,
        basketId,
        basketItemId,
        setBasketId,
        setBasketItemId,
        numberOfGuests,
        setNumberOfGuests,
      }}
    >
      {props.children}
    </OfferingContext.Provider>
  )
}
