import { useEffect, useLayoutEffect, useState } from 'react'
import { useOffering } from './Context'
import { PlusIcon, XIcon } from '@heroicons/react/solid'
import { Spinner } from '../Spinner'
import { useMemberLookup } from '../OrderHooks'
import { useTranslation } from 'react-i18next'
import { useCustomer } from '../CustomerHooks'

export const ContinueButton = ({ disabled, loading, onClick, label }) => (
  <button
    onClick={() => onClick()}
    disabled={disabled}
    className="relative overflow-hidden disabled:cursor-not-allowed disabled:opacity-50 items-center justify-center space-x-2 rounded-md p-3 shadow-sm font-medium text-on-accent bg-accent w-full flex hover:bg-accent/90 disabled:hover:bg-accent"
  >
    <span>{label}</span>

    {loading && (
      <div className="absolute inset-0 bg-accent flex flex-col justify-center items-center">
        <Spinner width="w-5" height="h-5" />
      </div>
    )}
  </button>
)

const GuestSelection = ({
  onComplete,
  disallowAmendGuests,
  ctaButtonText,
  errorMessage,
  requireMemberGuests,
  separateNameValuesEnforced,
  maxGuests,
}) => {
  const {
    guests,
    isCouples,
    addGuest,
    addGuests,
    removeGuest,
    setNameForGuest,
    setFirstNameForGuest,
    setLastNameForGuest,
    updateGuestDetails,
    numberOfGuests,
    setNumberOfGuests,
  } = useOffering()
  const { t } = useTranslation()
  const { data: { data: customer = {} } = {}, isLoading: isLoadingCustomer } = useCustomer()

  // Function to get the ID of a guest, starting with its permanent ID,
  // falling back to its ID, falling back to its index
  const getGuestId = (guest, index) => guest.id ?? guest._id ?? index

  const [selectedGuestIds, setSelectedGuestIds] = useState(() => {
    const guestIds = guests.map(getGuestId)

    if (isCouples) {
      // Just select the first two.
      return guestIds.slice(0, 2)
    }

    // Otherwise, preselect however many are in the `numberOfGuests` state.
    return guestIds.slice(0, numberOfGuests)
  })

  const [loading, setLoading] = useState(false)
  const [listRef, setListRef] = useState(null)
  const [guestDetailsValidationError, setGuestDetailsValidationError] = useState(false)

  const allGuestsHaveNames = guests
    .filter((guest) => selectedGuestIds.includes(getGuestId(guest)))
    .every(
      ({ is_lead_booker: isLead, name, firstName, lastName }) =>
        isLead ||
        (separateNameValuesEnforced === false && !!name) ||
        (separateNameValuesEnforced === true && !!firstName && !!lastName)
    )

  useEffect(() => {
    if (numberOfGuests > guests.length) {
      // We need to add some, and mark them all as selected
      const addedGuests = addGuests(numberOfGuests - guests.length)
      const addedGuestIds = addedGuests.map(getGuestId)
      setSelectedGuestIds(addedGuestIds)
    }

    return () => setLoading(false)
  }, [])

  useEffect(() => {
    // Don't do anything until we've checked for a logged in customer
    if (isLoadingCustomer) {
      return
    }

    const hasLeadBooker =
      guests.length > 0 &&
      guests.reduce((hasLeadBooker, guest) => hasLeadBooker || guest.is_lead_booker, false)

    if (hasLeadBooker) {
      return
    }

    // If there's just one guest, set it to be the lead booker
    if (guests.length === 1 && !guests[0].id) {
      const guestDetails = {
        ...guests[0],
        is_lead_booker: true,
        name: customer.full_name || '',
      }

      const newGuests = updateGuestDetails(0, guestDetails)
      setSelectedGuestIds(newGuests.map(getGuestId))
    } else if (guests.length === 0) {
      const addedGuest = addGuests(1, {
        is_lead_booker: true,
        name: customer.full_name || '',
        id: customer.id,
      })

      // Add a guest for the lead booker
      setSelectedGuestIds([getGuestId(addedGuest)])
    }
  }, [guests, customer, isLoadingCustomer])

  useEffect(() => {
    if (errorMessage !== undefined) {
      setLoading(false)
    }
  }, [errorMessage])

  useLayoutEffect(() => {
    if (listRef) {
      // Focus the last input
      const elms = [...listRef.querySelectorAll('input[type=text]')]

      elms.pop()?.focus()
    }
  }, [guests.length])

  const validateGuestDetails = (guests) => {
    return guests.every((guest) => {
      if (guest.is_lead_booker) {
        return true
      }

      if (separateNameValuesEnforced === false) {
        return !!guest.name
      }

      if (separateNameValuesEnforced === true) {
        //edge case for previously added guests
        if (guest.name !== '') {
          return true
        }

        return !!guest.firstName && !!guest.lastName
      }
    })
  }

  const handleComplete = async () => {
    setLoading(true)
    const selectedGuests = guests.filter((guest, index) =>
      selectedGuestIds.includes(getGuestId(guest, index))
    )

    if (!validateGuestDetails(guests)) {
      setGuestDetailsValidationError(true)
      setLoading(false)
      return
    }

    const formattedGuests = selectedGuests.map((guest) => {
      if (guest.name === '') {
        guest.name = `${guest.firstName} ${guest.lastName}`
        return {
          ...guest,
          name: `${guest.firstName} ${guest.lastName}`,
          first_name: guest.firstName,
          last_name: guest.lastName,
        }
      }
      return guest
    })

    if (!separateNameValuesEnforced) {
      await onComplete(selectedGuests)
    } else {
      await onComplete(formattedGuests)
    }
    setLoading(false)
  }

  const handleChange = (checked, guest, index) => {
    const changedGuestId = getGuestId(guest, index)

    if (checked) {
      setSelectedGuestIds([...selectedGuestIds, changedGuestId])
    } else {
      setSelectedGuestIds(selectedGuestIds.filter((guestId) => guestId !== changedGuestId))
    }
  }

  const handleNameChanged = (index, name) => {
    setNameForGuest(index, name)
  }

  const handleFirstNameChange = (index, firstName) => {
    setFirstNameForGuest(index, firstName)
  }

  const handleLastNameChange = (index, lastName) => {
    setLastNameForGuest(index, lastName)
  }

  const handleAddSomeoneElse = () => {
    handleChange(true, addGuest())
    setNumberOfGuests(numberOfGuests + 1)
  }

  const handleRemoveGuest = (index) => {
    handleChange(false, removeGuest(index))
    setNumberOfGuests(Math.max(numberOfGuests - 1, 1))
  }

  const setMemberGuestDetails = (index, memberDetails) => {
    const newGuests = updateGuestDetails(index, {
      name: [memberDetails.first_name, memberDetails.last_name].join(' '),
      customer_id: memberDetails.customer_id,
      membership_number: memberDetails.membership_number,
    })

    setSelectedGuestIds(newGuests.map(getGuestId))
  }

  return (
    <div className="space-y-2">
      <ul className="divide-y divide-gray-200" ref={setListRef}>
        {guests.map((guest, index) => (
          <li key={`guest_${index}`} className="flex space-x-2 items-center py-2">
            <input
              className="text-accent rounded border-gray-300 shadow-sm ml-0.5 disabled:opacity-50 focus:outline-none focus:ring focus:ring-accent"
              type="checkbox"
              defaultChecked={selectedGuestIds.includes(getGuestId(guest, index)) || !guest.id}
              disabled={
                (isCouples &&
                  selectedGuestIds.length === 2 &&
                  !selectedGuestIds.includes(getGuestId(guest, index))) ||
                loading
              }
              onChange={({ target }) => handleChange(target.checked, guest, index)}
              id={`guest-${guest.id}`}
            />

            {guest.id && !guest.is_lead_booker && (
              <label
                htmlFor={`guest-${guest.id}`}
                className="font-medium text-lg text-gray-700 flex-1 truncate"
              >
                {guest.name}
              </label>
            )}

            {!guest.id && guest.membership_number && (
              <label
                htmlFor={`guest-${guest.id}`}
                className="font-medium text-lg text-gray-700 flex-1 truncate"
              >
                {guest.name}
              </label>
            )}

            {guest.is_lead_booker && (
              <label
                htmlFor={`guest-${guest.id}`}
                className="font-medium text-lg text-gray-700 flex-1 truncate"
              >
                {t('frontend.check_availability.me')}
              </label>
            )}

            {!guest.id && !requireMemberGuests && !guest.is_lead_booker && (
              <>
                {separateNameValuesEnforced === false && (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-accent w-full disabled:cursor-not-allowed disabled:bg-gray-50"
                    placeholder={t('frontend.check_availability.guest_index_name', {
                      index: index + 1,
                    })}
                    disabled={loading}
                    value={guest.name ?? ''}
                    onChange={(e) => handleNameChanged(index, e.target.value)}
                  />
                )}
                {separateNameValuesEnforced && (
                  <>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-accent w-full disabled:cursor-not-allowed disabled:bg-gray-50"
                      placeholder={t('frontend.check_availability.guest_index_firstName', {
                        index: index + 1,
                      })}
                      disabled={loading}
                      value={guest.firstName ?? ''}
                      onChange={(e) => handleFirstNameChange(index, e.target.value)}
                    />
                    <input
                      type="text"
                      className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-accent w-full disabled:cursor-not-allowed disabled:bg-gray-50"
                      placeholder={t('frontend.check_availability.guest_index_lastName', {
                        index: index + 1,
                      })}
                      disabled={loading}
                      value={guest.lastName ?? ''}
                      onChange={(e) => handleLastNameChange(index, e.target.value)}
                    />
                  </>
                )}
                {guests.length > 1 && (
                  <button
                    onClick={() => handleRemoveGuest(index)}
                    disabled={loading}
                    title={t('frontend.check_availability.remove_guest')}
                  >
                    <XIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                )}
              </>
            )}

            {!guest.id &&
              !guest.membership_number &&
              !guest.is_lead_booker &&
              requireMemberGuests && (
                <>
                  <MemberSearch
                    onSelect={(memberDetails) => setMemberGuestDetails(index, memberDetails)}
                  />

                  {guests.length > 1 && (
                    <button
                      onClick={() => handleRemoveGuest(index)}
                      disabled={loading}
                      title={t('frontend.check_availability.remove_guest')}
                    >
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  )}
                </>
              )}
          </li>
        ))}
      </ul>

      {!disallowAmendGuests && (
        <button
          className="mx-auto border border-accent justify-center text-center w-full items-center space-x-1 rounded-md p-3 font-medium text-accent flex hover:bg-accent/10 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent"
          disabled={loading || (maxGuests && guests.length >= maxGuests)}
          onClick={handleAddSomeoneElse}
          title={t('frontend.check_availability.add_someone_else')}
        >
          <PlusIcon className="w-5 h-5" aria-hidden="true" />
          <span>{t('frontend.check_availability.add_someone_else')}</span>
        </button>
      )}

      <ContinueButton
        disabled={
          loading || selectedGuestIds.length === 0 || (isCouples && selectedGuestIds.length !== 2)
        }
        loading={loading}
        onClick={handleComplete}
        label={ctaButtonText}
      />

      {(selectedGuestIds.length === 0 || (isCouples && selectedGuestIds.length !== 2)) && (
        <div className="text-sm text-gray-500 text-center mt-1">
          {isCouples
            ? t('frontend.check_availability.need_to_select_two')
            : t('frontend.check_availability.need_to_select_at_least_one')}
        </div>
      )}

      {errorMessage && <div className="text-sm text-center mt-1 text-red-700">{errorMessage}</div>}

      {guestDetailsValidationError && (
        <div className="text-sm text-red-700 text-center mt-1">
          {t('frontend.check_availability.need_to_provide_all_names')}
        </div>
      )}
    </div>
  )
}

const MemberSearch = ({ loading, onSelect }) => {
  const [membershipNumber, setMembershipNumber] = useState('')
  const [lastName, setLastName] = useState('')
  const [lookupErrorMessage, setLookupErrorMessage] = useState(null)
  const { t } = useTranslation()

  const { data: { data: lookupResult } = {}, refetch: memberLookup } = useMemberLookup(
    membershipNumber,
    lastName,
    {
      enabled: false,
      onError: (response) => {
        switch (response.status) {
          case 401:
            setLookupErrorMessage(
              t('frontend.check_availability.member_search_errors.log_in_first')
            )
            break

          case 404:
            setLookupErrorMessage(t('frontend.check_availability.member_search_errors.no_results'))
            break

          case 422:
            setLookupErrorMessage(t('frontend.check_availability.member_search_errors.validation'))
            break

          case 429:
            setLookupErrorMessage(t('frontend.check_availability.member_search_errors.rate_limit'))
            break

          default:
            setLookupErrorMessage(t('frontend.check_availability.member_search_errors.general'))
            break
        }
      },
      onSuccess: (result) => {
        onSelect(result.data)
      },
    }
  )

  const handleMemberLookup = () => {
    setLookupErrorMessage(null)
    memberLookup()
  }

  const handleBlur = () => {
    if (membershipNumber && lastName) {
      handleMemberLookup()
    }
  }

  return (
    <div>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex-1">
          <input
            type="text"
            className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-accent w-full disabled:cursor-not-allowed disabled:bg-gray-50"
            placeholder={t('frontend.check_availability.membership_number')}
            value={membershipNumber}
            disabled={loading}
            onChange={(e) => setMembershipNumber(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        <div className="flex-1">
          <input
            type="text"
            className="border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-accent w-full disabled:cursor-not-allowed disabled:bg-gray-50"
            placeholder={t('frontend.check_availability.last_name')}
            value={lastName}
            disabled={loading}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {lookupErrorMessage && <div className="p-1 text-sm text-red-700">{lookupErrorMessage}</div>}
    </div>
  )
}

export const GuestConfig = ({
  onComplete,
  disallowAmendGuests = false,
  ctaButtonText,
  errorMessage,
  requireMemberGuests = false,
  separateNameValuesEnforced = false,
  maxGuests,
}) => {
  const { isCouples } = useOffering()
  const { t } = useTranslation()

  return (
    <div className="bg-white rounded-md p-6">
      <div className="mb-4">
        <h1 className="text-lg font-medium text-gray-700">
          {t('frontend.check_availability.who_is_this_for')}
        </h1>
        {isCouples && (
          <p className="text-gray-500 text-sm">
            {t('frontend.check_availability.couples_guest_config_subheading')}
          </p>
        )}
      </div>

      <GuestSelection
        onComplete={onComplete}
        disallowAmendGuests={disallowAmendGuests}
        ctaButtonText={ctaButtonText ?? t('frontend.check_availability.continue')}
        errorMessage={errorMessage}
        requireMemberGuests={requireMemberGuests}
        maxGuests={maxGuests}
        separateNameValuesEnforced={separateNameValuesEnforced}
      />
    </div>
  )
}
