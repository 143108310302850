import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../Spinner'
import { DurationFiltersV2, TimeSlotPickerV2 } from '../AvailabilityCheck/TimeSlotPickerV2'
import { SuggestedSlotPicker } from '../AvailabilityCheck/SuggestedSlotPicker'

export const ChoiceOptionDetail = ({
  offering,
  onGoBack,
  requiresSlots,
  slots,
  suggestedSlots = [],
  isLoading,
  onConfirm,
  onRemove,
  onPickTimeslot,
  applied,
  selectedDuration,
  setSelectedDuration,
}) => {
  const { t } = useTranslation()
  const [mutating, setMutating] = useState(false)

  const handleConfirm = async () => {
    setMutating(true)

    try {
      await onConfirm()
    } catch {
      // Error is handled in the parent component
    } finally {
      setMutating(false)
    }
  }

  const handleRemove = async () => {
    setMutating(true)

    try {
      await onRemove()
    } catch {
      // Error is handled in the parent component
    } finally {
      setMutating(false)
    }
  }

  const handlePickTimeslot = async (slot) => {
    setMutating(true)

    try {
      await onPickTimeslot(slot)
    } catch {
      // Error is handled in the parent component
    } finally {
      setMutating(false)
    }
  }

  return (
    <div className="space-y-4 relative pb-[100px]">
      <div className="relative aspect-offering overflow-hidden">
        <div className="h-1/3 bg-gradient-to-b from-black/50 to-transparent absolute inset-0" />
        <div className="h-1/3 bg-gradient-to-b from-transparent to-black/50 absolute top-auto inset-0" />
        <img
          src={offering.image?.url}
          className="w-full h-full aspect-offering object-center object-cover"
        />
        <button
          className="absolute top-0 left-0 font-medium m-3 text-sm text-left flex items-center space-x-2 text-white"
          onClick={onGoBack}
          disabled={mutating}
        >
          <div className="shadow w-8 h-8 flex items-center justify-center bg-white rounded-full">
            <ChevronLeftIcon className="w-5 h-5 text-black " />
          </div>

          <span>{t('frontend.check_availability.package_config.pick_something_different')}</span>
        </button>

        <div className="absolute bottom-0 left-0 m-6 text-2xl text-white font-bold">
          {offering.name}
        </div>
      </div>

      <div className="px-6 space-y-4">
        <p
          className="text-sm text-gray-500"
          dangerouslySetInnerHTML={{ __html: offering.description }}
        />

        {requiresSlots && (
          <>
            <div className="flex justify-between items-center">
              <div className="flex space-x-3 text-lg font-bold">
                <span>{t('frontend.check_availability.package_config.now_pick_a_time')}</span>
              </div>
              {offering?.durations && offering.durations.length > 1 && (
                <div className="text-xs">
                  <DurationFiltersV2
                    durations={offering.durations}
                    selectedDuration={selectedDuration}
                    onDurationSelect={setSelectedDuration}
                  />
                </div>
              )}
            </div>
            {suggestedSlots.length > 0 && (
              <div>
                <span className="text-sm">
                  {t('frontend.check_availability.package_config.pick_a_suggested_slot')}
                </span>
                <SuggestedSlotPicker
                  slots={suggestedSlots}
                  disabled={mutating}
                  isLoading={isLoading}
                  onSelect={handlePickTimeslot}
                />
                <div className="text-sm pt-5 -mb-3">
                  {t('frontend.check_availability.package_config.or_pick_a_different_time')}
                </div>
              </div>
            )}
            <div>
              <TimeSlotPickerV2
                slots={slots}
                disabled={mutating}
                isLoading={isLoading}
                onSelect={handlePickTimeslot}
              />
            </div>
          </>
        )}
      </div>

      {!requiresSlots && (
        <div className="shadow border-t border-gray-200 bg-white/75 backdrop-blur-sm p-4 absolute bottom-0 inset-x-0">
          {applied && (
            <div className="space-y-2">
              <button
                className={`relative overflow-hidden text-accent border border-accent mt-2 block text-center shadow-sm hover:bg-accent/80 font-medium rounded-lg w-full p-2 px-4 cursor-pointer focus:ring-accent focus:ring-2 focus:ring-offset-1`}
                onClick={() => onGoBack()}
                disabled={mutating}
              >
                {t('frontend.links.close')}
              </button>
              <button
                className={`relative overflow-hidden bg-accent text-on-accent mt-2 block text-center shadow-sm hover:bg-accent/80 font-medium rounded-lg w-full p-2 px-4 cursor-pointer focus:ring-accent focus:ring-2 focus:ring-offset-1`}
                onClick={handleRemove}
                disabled={mutating}
              >
                {!mutating && <span>{t('frontend.basket_slideover.basket_item.remove')}</span>}

                {mutating && <Spinner margin="m-0" />}
              </button>
            </div>
          )}

          {!applied && (
            <button
              className={`relative overflow-hidden bg-accent text-on-accent mt-2 block text-center shadow-sm hover:bg-accent/80 font-medium rounded-lg w-full p-2 px-4 cursor-pointer focus:ring-accent focus:ring-2 focus:ring-offset-1`}
              onClick={handleConfirm}
              disabled={mutating}
            >
              {!mutating && <span>{t('frontend.check_availability.add_to_order')}</span>}

              {mutating && <Spinner margin="m-0" />}
            </button>
          )}
        </div>
      )}
    </div>
  )
}
