var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ReactDOM from 'react-dom';
import { ApiClient } from '@trybeapp/sdk.shop';
import { ShareButton } from './shop/components/ShareButton';
import { AvailabilityCheck } from './shop/components/AvailabilityCheck';
import { BecomeAMember } from './shop/components/BecomeAMember';
import { BasketSlideover } from './shop/components/BasketSlideover/index';
import { BasketSummaryList } from './shop/components/BasketSummaryList';
import { PhoneInput } from './shop/components/PhoneInput';
import { VoucherLookup } from './shop/components/VoucherLookup';
import { ApplyVoucher } from './shop/components/ApplyVoucher';
import { ApplyCoupon } from './shop/components/ApplyCoupon';
import { AddCourseButton } from './shop/components/AddCourseButton';
import { AddVoucherButton } from './shop/components/AddVoucherButton';
import { CheckoutSummary } from './shop/components/CheckoutSummary';
import { ModalLink } from './shop/components/ModalLink';
import { CookiePolicy } from './shop/components/CookiePolicy';
import { CheckoutCompleteCheck } from './shop/components/CheckoutCompleteCheck';
import { CheckoutBasketSummary } from './shop/components/CheckoutBasketSummary';
import { NavMenu } from './shop/components/NavMenu';
import { AddRetailProductButton } from './shop/components/AvailabilityCheck/AddRetailProductButton';
import { HeroCta } from './shop/components/HeroCta';
import { CancelOrderItemButton } from './shop/components/CancelOrderItemButton';
import { GuestIntakeCheckbox } from './shop/components/GuestIntakeCheckbox';
import { Settings } from 'luxon';
import { LocalePicker } from './shop/components/LocalePicker';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { GuestIntakeMultipleChoice } from './shop/components/GuestIntakeMultipleChoice';
import resourcesToBackend from 'i18next-resources-to-backend';
import { registerLocale } from 'react-datepicker';
import Toggle from './shop/components/Toggle';
import ToggleUnavailableOfferings from './shop/components/ToggleUnavailableOfferings';
import { ApplyPromoCode } from './shop/components/ApplyPromoCode';
import { RetailCrossSell } from './shop/components/RetailCrossSell';
var timezone = document.querySelector('meta[name="site:timezone"]');
var currentLanguageElm = document.querySelector('meta[http-equiv="content-language"]');
var fallbackLocaleElm = document.querySelector('meta[name="fallback-locale"]');
export var locale = currentLanguageElm === null || currentLanguageElm === void 0 ? void 0 : currentLanguageElm.content;
if (locale !== 'en') {
    import("date-fns/locale/" + locale + "/index.js").then(function (datePickerLocale) {
        return registerLocale(locale, datePickerLocale);
    });
}
require('./bootstrap');
export var client = ApiClient.instance;
Settings.defaultLocale = locale;
if (timezone) {
    Settings.defaultZone = timezone.content;
}
client.enableCookies = true;
client.basePath = process.env.MIX_BASE_API_URL;
var componentsToRegister = [
    {
        tag: 'react-share-button',
        component: ShareButton,
    },
    {
        tag: 'react-availability-check',
        component: AvailabilityCheck,
    },
    {
        tag: 'react-basket-slideover',
        component: BasketSlideover,
    },
    {
        tag: 'react-voucher-lookup',
        component: VoucherLookup,
    },
    {
        tag: 'react-apply-voucher',
        component: ApplyVoucher,
    },
    {
        tag: 'react-apply-promo-code',
        component: ApplyPromoCode,
    },
    {
        tag: 'react-apply-coupon',
        component: ApplyCoupon,
    },
    {
        tag: 'react-basket-summary-list',
        component: BasketSummaryList,
    },
    {
        tag: 'react-checkout-basket-summary',
        component: CheckoutBasketSummary,
    },
    {
        tag: 'react-phone-input',
        component: PhoneInput,
    },
    {
        tag: 'react-add-course-button',
        component: AddCourseButton,
    },
    {
        tag: 'react-add-voucher-button',
        component: AddVoucherButton,
    },
    {
        tag: 'react-checkout-summary',
        component: CheckoutSummary,
    },
    {
        tag: 'react-checkout-complete-check',
        component: CheckoutCompleteCheck,
    },
    {
        tag: 'react-modal-link',
        component: ModalLink,
    },
    {
        tag: 'react-cookie-policy',
        component: CookiePolicy,
    },
    {
        tag: 'react-become-a-member',
        component: BecomeAMember,
    },
    {
        tag: 'react-nav-menu',
        component: NavMenu,
    },
    {
        tag: 'react-toggle',
        component: Toggle,
    },
    {
        tag: 'react-toggle-unavailable-offerings',
        component: ToggleUnavailableOfferings,
    },
    {
        tag: 'react-add-retail-product',
        component: AddRetailProductButton,
    },
    {
        tag: 'react-hero-cta',
        component: HeroCta,
    },
    {
        tag: 'react-cancel-order-item-button',
        component: CancelOrderItemButton,
    },
    {
        tag: 'react-guest-intake-checkbox',
        component: GuestIntakeCheckbox,
    },
    {
        tag: 'react-guest-intake-multiple-choice',
        component: GuestIntakeMultipleChoice,
    },
    {
        tag: 'react-locale-picker',
        component: LocalePicker,
    },
    {
        tag: 'react-retail-cross-sell',
        component: RetailCrossSell,
    },
];
i18n
    .use(
// Don't remove this underscore or everything will break
resourcesToBackend(function (language, _) { return __awaiter(void 0, void 0, void 0, function () {
    var platformTrans, shopOverridesElement, shopOverrides;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import("../../lang/" + language + ".json")];
            case 1:
                platformTrans = _a.sent();
                shopOverridesElement = document.getElementById('translation-overrides');
                shopOverrides = {};
                try {
                    shopOverrides = JSON.parse((shopOverridesElement === null || shopOverridesElement === void 0 ? void 0 : shopOverridesElement.innerHTML) || '{}');
                }
                catch (_b) {
                    shopOverrides = {};
                }
                return [2 /*return*/, Object.assign({}, platformTrans, shopOverrides)];
        }
    });
}); }))
    .use(initReactI18next)
    .init({
    fallbackLng: fallbackLocaleElm === null || fallbackLocaleElm === void 0 ? void 0 : fallbackLocaleElm.content,
    keySeparator: false,
    nsSeparator: false,
    lng: currentLanguageElm === null || currentLanguageElm === void 0 ? void 0 : currentLanguageElm.content,
    react: {
        useSuspense: false,
    },
})
    .then(function () {
    componentsToRegister.forEach(function (component) {
        return Array.from(document.getElementsByClassName(component.tag)).forEach(function (node) {
            var props = Object.assign({}, node.dataset);
            ReactDOM.render(<component.component {...props}/>, node);
        });
    });
});
